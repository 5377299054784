







































































































































































































































































































.broadcast-section {
  .align-item {
    display: inline-block;
    border: 1px solid transparent;
    padding: 3px 5px 0px 5px;
    border-radius: 5px;
    cursor: pointer;

    i {
      color: rgba(0, 27, 72, 0.54);
      font-size: 20px;
    }

    &.active {
      background: #4284f4;
      color: #FFF;

      i {
        color: #FFF;
      }
    }
  }

  .align-container {
    display: flex;
    background: #FFF;
    border-radius: 5px;
    margin-left: 20px;
  }

  .action-wrapper {
    max-width: 800px;  
  }

  .wizard-wrapper.tab-fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0px;
    z-index: 999;
    background: #FFF;

    .action-wrapper {
      max-width: unset;
      position: fixed;
      width: 100%;
      top: 0px;
      padding: 5px 5px;
      background: #646464;
      z-index: 1;

      .btn-group {
        .btn {
          margin-right: 2px;
        }
      }
    }
  }
}
