



























































































































































































































































































































































































































































































.email-create-step {
  max-width: 800px;

  .flatpickr-input {
    background: #FFF !important;
  }
}
